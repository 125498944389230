<template>
  <el-row>
    <el-container style="height: calc(93.5vh)">
      <el-header class="header">
        <el-row>
          <el-col :span="2" class="addReservation">
            <span
              class="el-icon-arrow-left"
              @click="returnOn"
              style="font-size: 24px; margin-top: 13px"
            ></span>
          </el-col>
          <el-col :span="4" class="addReservation">
            <router-link to="/add-reservation" class="cursor"
              >+添加预约</router-link
            >
          </el-col>
          <el-col :span="13" class="btn">
            <div
              :class="tabsChange === '1' ? 'btn1 left' : 'left'"
              @click="tabsChange = '1'"
            >
              看板
            </div>
            <div
              :class="tabsChange === '2' ? 'btn1 right' : 'right'"
              @click="tabsChange = '2'"
            >
              列表
            </div>
            <div class="time">
              <span class="el-icon-arrow-left"></span>
              <el-date-picker
                v-model="timePicker"
                :editable="false"
                type="date"
                placeholder="选择日期"
                align="center"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                prefix-icon="none"
                clear-icon="none"
              >
              </el-date-picker>
              <span class="el-icon-arrow-right"></span>
            </div>
          </el-col>
          <!-- <el-col :span="5" class="search">
            <el-input class="searchInput" v-model="searchVal" placeholder="8~20位数字或字母组合" prefix-icon="el-icon-search"
              @keyup.enter.native="searchChange">
            </el-input>
          </el-col> -->
        </el-row>
      </el-header>
      <el-container style="height: calc(93.5vh)">
        <el-container>
          <el-main class="main">
            <div v-if="tabsChange === '1'" class="header-time" v-dragscroll>
              <div class="ul-box" :style="{ width: boxwidth + 'px' }">
                <div class="li" v-for="(item, index) in timelist" :key="index">
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="lookList-box" v-for="(item, i) in list" :key="i">
                <div class="lookList-t">
                  <img :src="$getimgsrc(item.avatar)" alt="" />
                  <div class="name">
                    {{ item.realname }}
                  </div>
                  <div class="info">
                    {{ item.manypersonrole_id }}
                  </div>
                </div>
                <div class="lookList-b">
                  <div v-for="(item1, i1) in item.time" :key="i1">
                    <!--  -->
                    <!-- <div class="box box1" v-if="item1.per.length != 0" :style="'left:'+(i1*180)+'px'" @click="lookOrderDetail('服务中')">
                      <div class="name">{{ item1.per[0].username }}</div>
                      <div class="p">{{ item1.per[0].statuss }} <span v-if="item1.per[0].status == 1" @click.stop="LianXi(item1.per[0].id)">(点击表示已联系客户)</span> </div>
                      <div class="p">用户联系方式：{{ item1.per[0].usertel }}</div>
                      <div class="time"> <i class="el-icon-time"></i>{{ item1.per[0].yue_time }}</div>
                    </div> -->
                    <div
                      :class="
                        item1.per[0].status == 1
                          ? ' box box2'
                          : item1.per[0].status == 2
                          ? ' box box1'
                          : 'box box3'
                      "
                      v-if="item1.per.length != 0"
                      :style="'left:' + i1 * 180 + 'px'"
                      @click="
                        lookOrderDetail(
                          item1.per[0].status == 1
                            ? '已预约'
                            : item1.per[0].status == 2
                            ? '服务中'
                            : '已关闭',
                          item1.per[0].orderserid,
                        )
                      "
                    >
                      <div class="name">
                        {{ item1.per[0].username }} {{ item1.per[0].usertel }}
                      </div>
                      <div
                        class="p"
                        v-for="item2,i2 in item1.per[0].server"
                        :key="i2"
                      >
                        {{ item2.servername }}
                      </div>
                      <div class="time">
                        <i class="el-icon-time"></i>{{ item1.per[0].yue_time }}
                      </div>
                    </div>
                    <!-- <div class="box box3" v-else-if="item1===5" :style="'left:'+(i1*180)+'px'"
                      @click="lookOrderDetail('已关闭')">
                      <div class="name">赵晓梅 185585858583</div>
                      <div class="p">头皮平衡补水精华</div>
                      <div class="p">美白祛斑体验</div>
                      <div class="time"> <i class="el-icon-time"></i>09:00-10:15</div>
                    </div> -->
                    <div v-else class="box" :style="'left:' + i1 * 180 + 'px'">
                      <!-- {{item1}} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="list-box">
              <!-- <div class="list" v-for="(item,index) in PuTonglist" :key="index">
                <div class="list-doc">
                  <div class="doc-t" v-if="item.gzperson">
                    <img :src="$getimgsrc(item.gzperson.avatar)" alt="">
                    <div class="name">{{  item.gzperson.realname}}</div>
                    <div class="pro">{{  item.gzperson.manypersonrole_id}}</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>{{ item.gzperson.ban }}
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">{{  item.username}} {{  item.usertel}}</div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">回访</el-button>
                  </div>
                  <div class="info-b">{{  item.types}}</div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约时间</el-button>
                  </div>
                  <div class="pro-b">{{  item.yue_time}}</div>
                </div>
                <div class="list-btn">
                  lookOrderDetail('已关闭')
                  <el-button type="primary" size="mini" @click="showHuiFnag(item)">填写回访记录<i class="el-icon-arrow-right"></i> </el-button>
                </div>
                <div class="list-btn">
                  lookOrderDetail('已关闭')
                  <el-button type="primary" size="mini" @click="lookHuiFnag(item)">查看回访记录<i class="el-icon-arrow-right"></i> </el-button>
                </div>
              </div> -->
              <empty title="暂无内容" v-if="PuTonglist.length == 0"></empty>
              <div
                :class="
                  item.status == 1
                    ? ' list list2'
                    : item.status == 2
                    ? ' list list1'
                    : 'list list3'
                "
                v-for="(item, index) in PuTonglist"
                :key="index"
              >
                <div class="list-doc">
                  <div class="doc-t">
                    <img :src="$getimgsrc(item.gzperson.avatar)" alt="" />
                    <div class="name">{{ item.gzperson.realname }}</div>
                    <div class="pro">{{ item.gzperson.manypersonrole_id }}</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>{{ item.gzperson.ban }}
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">
                    {{ item.username }} {{ item.usertel }}
                  </div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约时间</el-button>
                  </div>
                  <div class="pro-b">{{ item.yue_time }}</div>
                </div>
                <!-- <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约项目</el-button>
                  </div>
                  <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
                </div> -->
                <div class="list-btn">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="
                      lookOrderDetail(
                        item.status == 1
                          ? '已预约'
                          : item.status == 2
                          ? '服务中'
                          : '已关闭',
                        item.orderserid,
                      )
                    "
                    >查看详情<i class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
              </div>
              <!-- <div class="list list2">
                <div class="list-doc">
                  <div class="doc-t">
                    <img src="@/assets/images/avatar.png" alt="">
                    <div class="name">顾小敏</div>
                    <div class="pro">美容师</div>
                  </div>
                  <div class="doc-b">
                    <i class="el-icon-time"></i>2020-01-03 09:00-10:15
                  </div>
                </div>
                <div class="list-info">
                  <div class="info-t">
                    <el-button size="mini">客户信息</el-button>
                  </div>
                  <div class="info-b">赵晓梅 15858585858</div>
                </div>
                <div class="list-pro">
                  <div class="pro-t">
                    <el-button size="mini">预约项目</el-button>
                  </div>
                  <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
                </div>
                <div class="list-btn">
                  <el-button type="primary" size="mini" @click="lookOrderDetail('已预约')">查看详情<i
                      class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
              </div> -->
            </div>
            <el-dialog title="回访记录" :visible.sync="huiFangShow" width="50%">
              <el-input
                type="textarea"
                :rows="10"
                v-model="huiFangInput"
                placeholder="请输入你的回访记录"
              ></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="huiFangShow = false">取 消</el-button>
                <el-button type="primary" @click="HuiFang">确 定</el-button>
              </span>
            </el-dialog>
            <el-dialog
              title="查看回访"
              :visible.sync="lookhuifangShow"
              width="50%"
            >
              <p>回访人：{{ lookitem.follow }}</p>
              <p v-if="lookitem.folltime">
                回访时间：{{ lookitem.folltime | timeform(true) }}
              </p>
              <el-input
                type="textarea"
                :rows="20"
                v-model="lookitem.content"
                placeholder="回访记录"
                disabled
              ></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="lookhuifangShow = false"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- <div class="fixed">
              <div class="type1"><i></i>已预约(2)</div>
              <div class="type2"><i></i>服务中(2)</div>
              <div class="type3"><i></i>已完成(2)</div>
              <div class="ref"><i class="el-icon-refresh"></i></div>
            </div> -->
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </el-row>
</template>

<script>
import empty from "../../module/empty.vue";
export default {
  components: { empty },
  data() {
    return {
      huiFangInput: "",
      lookhuifangShow: false,
      huiFangShow: false,
      searchVal: "",
      tabsChange: "1",
      listitem: {},
      list: [], //看板列表
      lookitem: {}, //查看回访
      boxwidth: 0,
      timelist: [], //时间列表
      PuTonglist: [], //列表
      timePicker:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
    };
  },
  created() {
    this.getime();
    this.getLookInfo();
    this.getList();
  },
  watch: {
    timePicker() {
      this.getList();
      this.getLookInfo();
    },
  },
  methods: {
    //查看回访
    lookHuiFnag(item) {
      this.lookitem = item;
      this.lookhuifangShow = true;
    },
    //准备回访的列表
    showHuiFnag(item) {
      if (item.type == 1) {
        this.listitem = item;
        this.huiFangShow = true;
      } else {
        this.$message.warning("该服务已回访");
      }
    },
    //回访
    HuiFang() {
      if (this.huiFangInput != "") {
        this.$http
          .post("api/store/booked/backhuifang", {
            id: this.listitem.id,
            content: this.huiFangInput,
          })
          .then((res) => {
            this.$message.success(res.data.msg);
            this.getList();
            this.huiFangShow = false;
          });
      } else {
        this.$message.warning("请填写回访记录");
      }
    },
    //获取列表
    getList() {
      this.$http
        .post("api/store/booked/pailiebiao", {
          time: this.timePicker,
        })
        .then((res) => {
          this.PuTonglist = res.data.data;
        });
    },
    //联系客户
    LianXi(id) {
      this.$http
        .post("api/store/booked/clickchange", {
          id: id,
        })
        .then((res) => {
          this.getLookInfo();
        });
    },
    //获取时间信息
    getime() {
      this.$http.post("api/store/booked/yuyuetime").then((res) => {
        this.timelist = res.data.data;
        this.boxwidth = this.timelist.length * 182;
      });
    },
    //获取看板信息
    getLookInfo() {
      this.$http
        .post("api/store/booked/paiworklist", {
          time: this.timePicker,
        })
        .then((res) => {
          this.list = res.data.data;
        });
    },
    // 返回上级
    returnOn() {
      // this.$router.go(-1);
      this.$router.push({
         path: "/work", 
        });
    },
    lookOrderDetail(pageName, id) {
      if (pageName == "已预约") {
        this.$router.push({
          name: "OrderDetail",
          params: {
            pageName,
            id,
          },
        });
      } else {
        this.$router.push({
          path: "/mian-ban", 
          query: {
            item:id
          },
        });

        // this.$router.push({
        //   name: "ServiceBills",
        // });
        window.sessionStorage.setItem("reservationPath", "/reservation");
      }
    },
    searchChange() {
      if (this.searchVal.trim()) {
        this.$router.push({
          name: "ReservationSearchList",
          params: {
            searchVal: this.searchVal.trim(),
          },
        });
      } else {
        this.$message.error("搜索内容不能为空");
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 头部
.header {
  background-color: #fff;
  color: #333;
  line-height: 50px;
  border-bottom: 1px solid #e9e9e9;

  .addReservation {
    color: #409eff;
    text-align: center;

    .cursor {
      color: #409eff;
    }
  }

  .btn {
    text-align: center;
    line-height: 30px;
    padding-top: 10px;

    .left,
    .right {
      display: inline-block;
      width: 100px;
      height: 30px;
      border: 1px solid #409eff;
      text-align: center;
      line-height: 30px;
      color: #409eff;
      cursor: pointer;
    }

    .time {
      margin-top: 5px;

      .el-icon-arrow-left,
      .el-icon-arrow-right {
        font-weight: bold;
        color: #666;
      }

      /deep/ .el-date-editor.el-input {
        width: 120px;

        .el-input__inner {
          border: 0;
          background-color: transparent;
          text-align: center;
          color: #409eff;
          padding: 0;
        }
      }
    }

    .left {
      border-right: 0;
      border-radius: 5px 0 0 5px;
    }

    .right {
      border-radius: 0 5px 5px 0;
    }

    .btn1 {
      border: 1px solid #409eff;
      text-align: center;
      line-height: 30px;
      color: #fff;
      background-color: #409eff;
    }
  }

  .search {
    /deep/ .el-input__inner {
      height: 35px;
      border-radius: 35px;

      &:focus {
        border-color: #dcdfe6;
      }
    }
  }
}

.main {
  width: 100%;
  background-color: #fff;

  .header-time {
    width: 100%;
    height: calc(100vh - 80px);
    overflow-x: scroll;
    overflow-y: scroll;
    position: relative;
    background-color: #f9f9f9;

    &::-webkit-scrollbar {
      display: none;
    }

    .ul-box {
      width: 2910px;
      color: #666;
      font-weight: bold;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      border-bottom: 1px solid #e9e9e9;

      .li {
        width: 180px;
        height: auto;
        height: 50px;
        line-height: 50px;

        &:first-child {
          padding-left: 30px;
        }

        .time {
          text-align: center;
        }
      }
    }

    .lookList-box {
      display: flex;

      .lookList-t {
        text-align: center;
        padding: 10px 10px 0;
        height: 100px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }

        .name {
          width: 100px;
          font-size: 14px;
          margin: 5px 0;
        }

        .info {
          font-size: 12px;
          color: #999;
        }
      }

      .lookList-b {
        display: flex;
        justify-content: flex-start;
        position: relative;

        .box {
          position: absolute;
          z-index: 1;
          box-sizing: border-box;
          width: 180px;
          height: 120px;
          padding: 15px 10px;
          font-size: 12px;
          line-height: 24px;
          color: #999;
          box-sizing: border-box;
          border-left: 1px solid #e9e9e9;
        }

        .box1 {
          position: absolute;
          width: 270px;
          color: #fec03d;
          background-color: #fff9ec;
          border-left: 0;
          border-left: 3px solid #fec03d;
        }

        .box2 {
          position: absolute;
          width: 270px;
          color: #51d351;
          background-color: #eefbee;
          border-left: 3px solid #51d351;
        }

        .box3 {
          position: absolute;
          width: 270px;
          color: #24d2d3;
          background-color: #e5f6f6;
          border-left: 3px solid #24d2d3;
        }
      }
    }
  }

  .list-box {
    padding: 10px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    min-height: calc(100vh - 80px);

    .list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      border-left: 4px solid #24d2d3;
      color: #666;
      font-size: 12px;
      background-color: #fff;
      margin-bottom: 10px;

      .list-doc {
        width: 30%;

        .doc-t {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;

          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }

          .name {
            margin: 0 8px;
          }

          .pro {
            color: #999;
          }
        }
      }

      .list-info {
        width: 20%;
      }

      .list-pro {
        width: 20%;

        .pro-b {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .info-t,
      .pro-t {
        margin-bottom: 18px;

        /deep/.el-button:focus,
        .el-button:hover {
          color: #666 !important;
          border-color: #dcdfe6 !important;
          background-color: #ffffff !important;
        }
      }

      .list-btn {
        width: 20%;
        text-align: center;

        /deep/.el-button:focus,
        .el-button:hover {
          color: #fff !important;
          border-color: #409eff !important;
          background-color: #409eff !important;
        }
      }
    }

    .list1 {
      border-left: 4px solid #ffa94c;
    }

    .list2 {
      border-left: 4px solid #51d351;
    }
  }
}

.fixed {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;

  .type1,
  .type2,
  .type3 {
    i {
      width: 8px;
      height: 8px;
      margin-right: 3px;
      border-radius: 2px;
      display: inline-block;
    }
  }

  .type1 {
    i {
      background-color: #82d588;
    }
  }

  .type2 {
    i {
      background-color: #ffa94c;
    }
  }

  .type3 {
    i {
      background-color: #24d2d3;
    }
  }

  .ref {
    font-size: 16px;
  }
}

/deep/.header.el-header {
  height: 80px !important;
}

.menu.el-menu {
  border-right: none !important;
  background-color: #f9f9f9;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f9f9f9;
}

.main.el-main {
  padding: 0 0px !important;
}
</style>
